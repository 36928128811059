<template>
  <div>
    <button @click="get">get请求</button>
    <div>请求结果:{{ getValue }}</div>
    <hr>
    <button @click="post">post请求</button>
    <div>请求结果:{{ postValue }}</div>
  </div>
  <div class="base">
    <img src="@/images/备案图标.png" alt="">
    <a href="https://beian.mps.gov.cn/#/query/webSearch?code=33010502012069" rel="noreferrer" target="_blank">浙公网安备33010502012069</a>
    <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2024126155号</a>
  </div>
</template>

<script setup lang="js">
import { ref } from 'vue'
import axios from 'axios'

let getValue = ref()
let postValue = ref()

async function get () {
  getValue.value = 111
  let url = 'https://www.looklookcode.com/test/v1/get'
  let result = await axios.get(url, { params: { username: '张飞' } })
  getValue.value = result.data
}

async function post () {
  let url = 'https://www.looklookcode.com/test/v1/post'
  let result = await axios.post(url, { username: '关羽' })
  postValue.value = result.data
}

</script>

<style>
.base {
  position: fixed;      /* 将元素固定在屏幕的指定位置 */
  bottom: 0;            /* 定位到页面底部 */
  width: 100%;          /* 使容器宽度占满屏幕 */
  text-align: center;   /* 水平居中对齐内部内容 */
}
a{
  margin-right: 10px;
}
img{
  margin-right: 5px;
  height: 18px;
}

</style>
